import { graphql, useStaticQuery } from "gatsby";
import React, { useRef, useState } from "react";
import Lottie from 'react-lottie';
import "react-multi-carousel/lib/styles.css";
import Header from '../components/Header/Header-2';
import Layout from '../components/Layout/Layout';
import ContactPopup from '../components/Popup/contact-popup';
import SEO from "../components/seo";
import animationData1 from '../images/animations/anim1.json';
import animationData3 from '../images/animations/anim3.json';
import animationData4 from '../images/animations/anim4.json';
import animationData5 from '../images/animations/anim5.json';
import animationData6 from '../images/animations/anim6.json';
import animationData7 from '../images/animations/anim7.json';
import { isValidEmail } from '../utils/utils';

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDoxNjg1OQ==") {
      uri
      title
      slug
      content
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const HowItWork = () => {
  const captchaRef = useRef(null);
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  const desc = data.wpgraphql.page.seo.metaDesc
  const seo = data.wpgraphql.page.seo
  const uri = data.wpgraphql.page.uri
  //
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firm, setFirm] = useState('');
  const [average, setAverage] = useState('0');
  const [found, setFound] = useState();
  //
  const submit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if (!token) {
      alert('Please Confirm You Are Not a Robot!');
    } else {
      // check 
      const response = await fetch("https://api.trustyip.com/recaptcha/verify?token=" + token);
      const responseJson = await response.json();
      if ((responseJson.data.success) || (responseJson.data['error-codes'][0] === 'timeout-or-duplicate')) {
        if (firstName && lastName && email) {
          // validate email
          if (!isValidEmail(email)) {
            alert('Email is invalid!!!');
            return;
          }
          const data = {
            email: email,
            name: firstName + ' ' + lastName,
            phone: phone || '',
            subject: `[PowerPatent] New message from ${email}`,
            message: `
                          <p>Firm Name: ${firm}</p>
                          <p>Average # patent application per month: ${average}</p>
                          <p>${found}</p>
                      `,
            site: 'powerpatent.com'
          }
          // send mail
          fetch("https://api.trustyip.com/subcribers/add", {
            "method": "POST",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
            "body": JSON.stringify(data)
          })
            .then(response => response.json())
            .then(response => {
              alert('Thank you for contacting us. We will feedback to you soon!!!');
              // reset
              setEmail('');
              setFirstName('');
              setLastName('');
              setPhone('');
              setFirm('');
              setAverage('0');
              setFound('');
            })
            .catch(err => {
              alert('There is something wrong. Please try again later!');
              console.log(err);
            });
          // close popup
          // childRef.current?.close();
        } else {
          alert('Please fill in all fields.');
        }
      }
    }

  }
  const childRef = useRef();
  const openPopup = () => {
    console.log('open contact popup');
    childRef.current?.open();
  }
  //
  const getLottieOptions = (data) => {
    return {
      loop: true,
      autoplay: true,
      animationData: data,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }
  return (
    <>
      <Layout>
        <SEO title="How It Works" description={desc} canonical={'/how-it-works'} seo={seo} />
        <Header home={false} menu='how' />
        <main>
          <section className="py-5 bg-lightgrey">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <h1 className="hiw-title mb-4 text-primary">Jump Start <br /> your Drafting</h1>
                  <p className="hiw-desc mb-5"> Get first patent drafts in minutes through PowerPatent's
                    AI-powered first draft co-pilot technology</p>
                  <div className="d-flex gap-4 flex-wrap">
                    <button onClick={openPopup} className="btn btn-lg px-4 btn-warning">Request Demo</button>
                    {/* <a href="#" className="btn btn-lg px-4 btn-primary">Get sample draft</a> */}
                  </div>
                </div>
                <div className="col-md-7 pt-md-0 pt-5">
                  <div id="animation1">
                    <Lottie options={getLottieOptions(animationData1)} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="py-5 bg-sky">
            <div className="container">
              {/* <div className="box-shadow anim-box bg-white"> */}
              <div className="row align-items-center">
                <div className="col-md-10 offset-md-1">
                  <iframe src="https://player.vimeo.com/video/646478178?h=fe6e877622" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
              {/* </div> */}
            </div>
          </section>

          <section className="py-5">
            <div className="container">
              <h4 className="hiw-tagline">WHY CHOOSE POWERPATENT?</h4>
              <h3 className="hiw-subtitle py-2">Save time, amaze clients, and file-first</h3>
              <div className="position-relative">
                <div id="animation2" className="d-md-block d-none"></div>
                <div className="row">
                  <div className="col-md-6 py-lg-5 py-4 pe-lg-5 pe-md-4">
                    <div className="box-shadow h-100 why-box">
                      <div className="d-flex align-items-center gap-4 mb-3">
                        <img src="/assets/img/icons/save-time-1.png" alt="Icon" className="why-icon" />
                        <h3 className="why-title"> Efficiency and Time Savings</h3>
                      </div>
                      <p className="why-desc">PowerPatent's advanced AI technology automates and streamlines
                        the patent drafting process. It generates comprehensive first drafts of patent
                        applications from summary/claims and figure annotations, saving significant time
                        and effort for patent attorneys and inventors. This efficiency allows legal
                        teams to focus on higher-value tasks and increases overall productivity.</p>
                    </div>
                  </div>
                  <div className="col-md-6 py-lg-5 py-4 ps-lg-5 ps-md-4">
                    <div className="box-shadow h-100 why-box">
                      <div className="d-flex align-items-center gap-4 mb-3">
                        <img src="/assets/img/icons/quality-1.png" alt="Icon" className="why-icon" />
                        <h3 className="why-title"> Accuracy and Quality</h3>
                      </div>
                      <p className="why-desc">PowerPatent's AI-powered drafting capabilities ensure accuracy
                        and quality in patent applications. By leveraging machine learning algorithms,
                        PowerPatent analyzes vast amounts of patent data to provide accurate and
                        relevant content, minimizing errors and enhancing the overall quality of the
                        drafted applications.</p>
                    </div>
                  </div>
                  <div className="col-md-6 py-lg-5 py-4 pe-lg-5 pe-md-4">
                    <div className="box-shadow h-100 why-box">
                      <div className="d-flex align-items-center gap-4 mb-3">
                        <img src="/assets/img/icons/partner-1.png" alt="Icon" className="why-icon" />
                        <h3 className="why-title"> Collaboration and Communication</h3>
                      </div>
                      <p className="why-desc">PowerPatent facilitates collaboration among team members and
                        outside counsel. Its cloud-based platform enables seamless sharing and editing
                        of patent application drafts, making it easy for multiple stakeholders to
                        collaborate in real-time. This enhances communication and promotes efficient
                        teamwork, leading to faster and more accurate patent filings.</p>
                    </div>
                  </div>
                  <div className="col-md-6 py-lg-5 py-4 ps-lg-5 ps-md-4">
                    <div className="box-shadow h-100 why-box">
                      <div className="d-flex align-items-center gap-4 mb-3">
                        <img src="/assets/img/icons/contact-1.png" alt="Icon" className="why-icon" />
                        <h3 className="why-title"> Inventor-Friendly Interface</h3>
                      </div>
                      <p className="why-desc">PowerPatent offers an intuitive and user-friendly interface for
                        inventors. It simplifies the invention disclosure capture process, allowing
                        inventors to provide detailed descriptions, annotations, and drawings easily.
                        This user-centric approach promotes inventor engagement and ensures accurate
                        representation of the invention in the patent application.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="py-5 bg-sky">
            <div className="container">
              <div className="row align-items-center mb-5">
                <div className="col-lg-6 col-md-8">
                  <h4 className="hiw-tagline">The PowerPatent</h4>
                  <h3 className="hiw-subtitle py-2">PowerPatent demands set the standard for excellence</h3>
                </div>
                <div className="col-lg-6 col-md-4 pt-md-0 pt-4">
                  <div className="d-flex gap-4 flex-wrap justify-content-md-end">
                  <button onClick={openPopup} className="btn btn-lg px-4 btn-warning">Request Demo</button>
                    {/* <a href="#" className="btn btn-lg px-4 btn-primary">Get sample first draft</a> */}
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="box-shadow anim-box bg-white">
                  <div className="row gap-md-0 gap-4 align-items-center flex-md-row flex-column">
                    <div className="col-md-6">
                      <div className="lotie-anim" id="animation3">
                        <Lottie options={getLottieOptions(animationData3)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="anim-title pb-4">Cutting-Edge AI Technology</h3>
                      <p className="anim-desc">PowerPatent harnesses the power of advanced artificial
                        intelligence technology to revolutionize the patent drafting process. Its
                        state-of-the-art algorithms and machine learning capabilities enable the
                        generation of accurate, detailed, and high-quality patent applications. By
                        leveraging AI, PowerPatent stays at the forefront of technological advancements,
                        ensuring its users benefit from the latest innovations in the field.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="box-shadow anim-box bg-white">
                  <div className="row gap-md-0 gap-4 align-items-center flex-md-row-reverse flex-column">
                    <div className="col-md-6">
                      <div className="lotie-anim" id="animation4">
                        <Lottie options={getLottieOptions(animationData4)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="anim-title pb-4">Unparalleled Accuracy and Quality</h3>
                      <p className="anim-desc">PowerPatent's drafting tools deliver unparalleled accuracy and
                        quality in patent applications. The AI-driven system analyzes vast amounts of
                        patent data, ensuring that the generated drafts adhere to patent office
                        guidelines, include comprehensive claims, and accurately describe the invention.
                        By maintaining a high standard of precision, PowerPatent helps users produce
                        patent applications that stand up to scrutiny and maximize the chances of
                        successful prosecution. </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="box-shadow anim-box bg-white">
                  <div className="row gap-md-0 gap-4 align-items-center flex-md-row flex-column">
                    <div className="col-md-6">
                      <div className="lotie-anim" id="animation5">
                        <Lottie options={getLottieOptions(animationData5)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="anim-title pb-4">User-Friendly Interface</h3>
                      <p className="anim-desc">PowerPatent places a strong emphasis on user experience and
                        provides a user-friendly interface that simplifies the patent drafting process.
                        The intuitive design and seamless navigation make it easy for inventors, patent
                        attorneys, and legal teams to work efficiently and effectively within the
                        platform. PowerPatent's user-centric approach ensures that users can focus on
                        capturing the essence of their inventions without getting bogged down by
                        complicated tools or workflows.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="box-shadow anim-box bg-white">
                  <div className="row gap-md-0 gap-4 align-items-center flex-md-row-reverse flex-column">
                    <div className="col-md-6">
                      <div className="lotie-anim" id="animation6">
                        <Lottie options={getLottieOptions(animationData6)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="anim-title pb-4">Advanced Collaboration and Review Capabilities</h3>
                      <p className="anim-desc">PowerPatent harnesses the power of advanced artificial
                        intelligence technology to revolutionize the patent drafting process. Its
                        state-of-the-art algorithms and machine learning capabilities enable the
                        generation of accurate, detailed, and high-quality patent applications. By
                        leveraging AI, PowerPatent stays at the forefront of technological advancements,
                        ensuring its users benefit from the latest innovations in the field.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="box-shadow anim-box bg-white">
                  <div className="row gap-md-0 gap-4 align-items-center flex-md-row flex-column">
                    <div className="col-md-6">
                      <div className="lotie-anim" id="animation7">
                        <Lottie options={getLottieOptions(animationData7)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="anim-title pb-4">Continuous Innovation and Updates</h3>
                      <p className="anim-desc">PowerPatent is committed to continuous innovation and regularly
                        updates its toolset to meet evolving industry needs. The team behind PowerPatent
                        keeps a close eye on industry trends, user feedback, and advancements in AI
                        technology to refine and enhance the platform's capabilities. By staying ahead
                        of the curve and consistently improving its features, PowerPatent ensures that
                        users have access to the most cutting-edge tools and techniques for patent
                        drafting.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="box-shadow anim-box bg-white">
                  <div className="row gap-md-0 gap-4 align-items-center flex-md-row-reverse flex-column">
                    <div className="col-md-6">
                      <div className="lotie-anim" id="animation8">
                        <img src="/assets/img/animations/powerpatent-testimonial.gif" alt="Animation"
                          className="img-fluid" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="anim-title pb-4">Industry Recognition and Trust</h3>
                      <p className="anim-desc">PowerPatent has earned the trust and recognition of the patent
                        law community. Many law firms, corporations, and inventors rely on PowerPatent
                        for their patent drafting needs, and the platform has garnered positive reviews
                        and testimonials from satisfied users. PowerPatent's track record of delivering
                        excellent results and its commitment to customer satisfaction solidify its
                        position as a trusted and reputable provider of patent drafting solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="pt-5 pb-b mt-3 text-center hiw-desc"> In summary, PowerPatent sets the standard for
                excellence in patent drafting through its advanced AI technology, unparalleled accuracy,
                user-friendly interface, collaboration capabilities, continuous innovation, and industry
                recognition. By leveraging these strengths, PowerPatent empowers users to produce high-quality
                patent applications efficiently and effectively, establishing itself as a leader in the field of
                patent drafting. </p>
            </div>
          </section>

          <section className="py-5">
            <div className="container">
              <div className="text-center">
                <h4 className="hiw-tagline">HOW IT WORKS</h4>
                <h3 className="hiw-subtitle py-2">An AI-driven solution for winning patent application claims</h3>
              </div>
              <div className="row py-4">
                <div className="col-md-4 py-md-0 py-3 text-center">
                  <img src="/assets/img/icons/folder-1.svg" alt="Icon" className="how-img" />
                  <h4 className="how-title my-3">Upload Invntion Information</h4>
                  <p className="how-desc">Simply upload the claims and the drawings and let the magic begins.</p>
                </div>
                <div className="col-md-4 py-md-0 py-3 text-center">
                  <img src="/assets/img/icons/image-19.svg" alt="Icon" className="how-img" />
                  <h4 className="how-title my-3">Let the AI work</h4>
                  <p className="how-desc">We leverage your patent claims or summary and the part list of each
                    drawing to create long form documentation of the invention. We can handle chemical
                    formulas too!</p>
                </div>
                <div className="col-md-4 py-md-0 py-3 text-center">
                  <img src="/assets/img/icons/assurance-1.svg" alt="Icon" className="how-img" />
                  <h4 className="how-title my-3">We write the story</h4>
                  <p className="how-desc">Our generative AI compiles the data gathered into a compelling patent
                    first draft for your inventor!</p>
                </div>
              </div>
            </div>
          </section>

          <section className="py-5 bg-sky">
            <div className="container">
              <div className="text-center">
                <h3 className="anim-title pb-3">First patent Application Drafts in Minutes</h3>
                <p className="mb-0">While flagging Section 112 issues and claim support</p>
                <p>Drafts from specific technology areas and language.
                  Yes, we are multilingual!</p>
              </div>
              <div className="row m-0">
                <div className="col-md-12 py-4 px-0">
                  <div className="d-flex justify-content-center">
                    <div>
                      <p className="how-desc">
                        In only a 15-minute call, Our team will show you how PowerPatent helps your law firm:
                      </p>
                      <p className="how-desc">
                        <i class="fa fa-check"></i> Professtional 5+ hours per application catch 112 issues by 30% or more
                      </p>
                      <p className="how-desc">
                        <i class="fa fa-check"></i> Get allowances faster with claim support
                      </p>
                    </div>

                  </div>
                  <div className="d-flex justify-content-center">.
                  <button onClick={openPopup} className="btn btn-lg px-4 btn-warning">Request Demo</button>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>
        <ContactPopup ref={childRef}></ContactPopup>
      </Layout>
    </>
  )
}
export default HowItWork
